import { FichaTecnicaCampo } from "./ficha-tecnica-campo.model";

export class FichaTecnica {

    id: number;
    nome: string = '';
    situacao: string = 'A';
    campos: FichaTecnicaCampo[] = [];
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}