import { Projeto } from 'src/app/models/projeto.model';
import { ProjetoService } from 'src/app/services/class/projeto.service';
// default
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-projetos-criar',
  templateUrl: './projetos-criar.component.html',
  styleUrls: ['./projetos-criar.component.scss']
})
export class ProjetosCriarComponent implements OnInit {

  data: Projeto = new Projeto();

  file: File;
  fileImagemDepoimento: File;
  progress: any;

  constructor(
    public helper: HelperService,
    public projetoService: ProjetoService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public global: GlobalService
  ) { }

  ngOnInit(): void {

  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo item...');

    this.projetoService.post(this.data)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/projeto/imagem?id=${res}`);
        this.submitImages(this.fileImagemDepoimento, `/projeto/imagemDepoimento?id=${res}`);
        this.router.navigate(['/projetos']).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('Alterando imagem...');
    this.submitImages(this.file, '/projeto/imagem?id=-99').then((res: any) => {
      this.data.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  @ViewChild('fileImagemDepoimentoInput', { static: true }) fileImagemDepoimentoInput: ElementRef;
  onFileImagemDepoimentoSelected(files) {
    this.fileImagemDepoimento = files.item(0);
    this.loadingService.present('Alterando...');
    this.submitImages(this.fileImagemDepoimento, '/projeto/imagemDepoimento?id=-99')
      .then((res: any) => {
        this.data.imagemDepoimento = res.body;
        this.loadingService.dismiss();
      }).catch(() => {
        this.progress = 0;
        this.fileImagemDepoimentoInput.nativeElement.value = '';
        this.loadingService.dismiss();
      });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.projetoService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImagem() {
    this.data.imagem = '';
  }

  removeImagemDepoimento() {
    this.data.imagemDepoimento = '';
  }

}
