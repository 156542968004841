import { NgModule } from '@angular/core';
// Material
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { getBrazilianPaginatorIntl } from './brazilian-paginator-intl';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatTabsModule } from '@angular/material/tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { MatDividerModule } from '@angular/material/divider';
// components
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalResetPasswordComponent } from './components/modal-reset-password/modal-reset-password.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ContatoComponent } from './pages/contato/contato.component';
// locale
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { FilterPipe } from './pipes/filter.pipe';
import { ParceirosComponent } from './pages/parceiros/parceiros.component';
import { ParceirosCriarComponent } from './pages/parceiros-criar/parceiros-criar.component';
import { ParceirosEditarComponent } from './pages/parceiros-editar/parceiros-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { FotosComponent } from './pages/fotos/fotos.component';
import { VideosComponent } from './pages/videos/videos.component';
import { VideosCriarComponent } from './pages/videos-criar/videos-criar.component';
import { VideosEditarComponent } from './pages/videos-editar/videos-editar.component';
import { LinhasComponent } from './pages/linhas/linhas.component';
import { LinhasCriarComponent } from './pages/linhas-criar/linhas-criar.component';
import { LinhasEditarComponent } from './pages/linhas-editar/linhas-editar.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { ProdutosCriarComponent } from './pages/produtos-criar/produtos-criar.component';
import { ProdutosEditarComponent } from './pages/produtos-editar/produtos-editar.component';
import { ProjetosComponent } from './pages/projetos/projetos.component';
import { ProjetosCriarComponent } from './pages/projetos-criar/projetos-criar.component';
import { ProjetosEditarComponent } from './pages/projetos-editar/projetos-editar.component';
import { TiposProdutoComponent } from './pages/tipos-produto/tipos-produto.component';
import { TiposProdutoCriarComponent } from './pages/tipos-produto-criar/tipos-produto-criar.component';
import { TiposProdutoEditarComponent } from './pages/tipos-produto-editar/tipos-produto-editar.component';
import { ModelosProdutoComponent } from './pages/modelos-produto/modelos-produto.component';
import { ModelosProdutoCriarComponent } from './pages/modelos-produto-criar/modelos-produto-criar.component';
import { ModelosProdutoEditarComponent } from './pages/modelos-produto-editar/modelos-produto-editar.component';
import { FichasTecnicasComponent } from './pages/fichas-tecnicas/fichas-tecnicas.component';
import { FichasTecnicasCriarComponent } from './pages/fichas-tecnicas-criar/fichas-tecnicas-criar.component';
import { FichasTecnicasEditarComponent } from './pages/fichas-tecnicas-editar/fichas-tecnicas-editar.component';
registerLocaleData(localePt);

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ModalResetPasswordComponent,
    DialogComponent,
    LoadingComponent,
    // pipes
    FilterPipe,
    // pages
    HomeComponent,
    LoginComponent,
    SobreComponent,
    UsuariosComponent,
    UsuariosCriarComponent,
    UsuariosEditarComponent,
    ContatoComponent,
    ParceirosComponent,
    ParceirosCriarComponent,
    ParceirosEditarComponent,
    FotosComponent,
    VideosComponent,
    VideosCriarComponent,
    VideosEditarComponent,
    LinhasComponent,
    LinhasCriarComponent,
    LinhasEditarComponent,
    ProdutosComponent,
    ProdutosCriarComponent,
    ProdutosEditarComponent,
    ProjetosComponent,
    ProjetosCriarComponent,
    ProjetosEditarComponent,
    TiposProdutoComponent,
    TiposProdutoCriarComponent,
    TiposProdutoEditarComponent,
    ModelosProdutoComponent,
    ModelosProdutoCriarComponent,
    ModelosProdutoEditarComponent,
    FichasTecnicasComponent,
    FichasTecnicasCriarComponent,
    FichasTecnicasEditarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    // Material
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatDividerModule,
    NgxDropzoneModule,
    NgxSummernoteModule,
    DragDropModule
  ],
  entryComponents: [
    DialogComponent,
    ModalResetPasswordComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getBrazilianPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
