import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModeloProduto } from 'src/app/models/modelo-produto.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ModeloProdutoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<ModelosProdutosApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/modeloProduto/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/modeloProduto/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/modeloProduto/buscar?id=${id}`);
  }

  getSelect(): Observable<any> {
    return this.api.get(`/select/modeloProduto/buscar`);
  }

  post(modeloProduto: ModeloProduto): Observable<any> {
    return this.api.post('/modeloProduto/inserir', modeloProduto);
  }

  patch(modeloProduto: ModeloProduto): Observable<any> {
    return this.api.post('/modeloProduto/alterar', modeloProduto);
  }

  delete(modeloProduto: ModeloProduto): Observable<any> {
    return this.api.post('/modeloProduto/deletar', modeloProduto);
  }

  deleteSelected(modelosProduto: ModeloProduto[]): Observable<any> {
    return this.api.post('/modeloProduto/deletarLista', modelosProduto);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: ModeloProduto, v2: ModeloProduto): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface ModelosProdutosApi {
  modelosProduto: ModeloProduto[];
  numeroPaginas: number;
}
