import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FichaTecnicaCampo } from 'src/app/models/ficha-tecnica-campo.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class FichaTecnicaCampoService {

  constructor(public api: ApiService) { }

  get(idFicha: number): Observable<any> {
    return this.api.get(`/fichaTecnicaCampo/buscar?idFicha=${idFicha}`);
  }

  getById(id: number): Observable<any> {
    return this.api.get(`/fichaTecnicaCampo/buscar?id=${id}`);
  }

  post(campo: FichaTecnicaCampo): Observable<any> {
    return this.api.post('/fichaTecnicaCampo/inserir', campo);
  }

  patch(campo: FichaTecnicaCampo): Observable<any> {
    return this.api.post('/fichaTecnicaCampo/alterar', campo);
  }

  delete(campo: FichaTecnicaCampo): Observable<any> {
    return this.api.post('/fichaTecnicaCampo/deletar', campo);
  }

  order(campos: FichaTecnicaCampo[]) {
    return this.api.post(`/fichaTecnicaCampo/ordenar`, campos);
  }

  deleteSelected(campos: FichaTecnicaCampo[]): Observable<any> {
    return this.api.post('/fichaTecnicaCampo/deletarLista', campos);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
