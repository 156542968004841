// default
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from './../../services/global.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GaleriaFoto } from 'src/app/models/galeria-foto.model';
import { FotosApi, GaleriaFotoService } from 'src/app/services/class/galeria-foto.service';
import { HttpProgressEvent } from '@angular/common/http';

@Component({
  selector: 'app-fotos',
  templateUrl: './fotos.component.html',
  styleUrls: ['./fotos.component.scss']
})
export class FotosComponent implements OnInit {

  data: GaleriaFoto[] = [];

  isLoadingResults = true;
  isRateLimitReached = false;

  page: number = 1;
  itemsPerPage: number = 12;

  files: File[] = [];

  progress: number = 0;

  constructor(
    public global: GlobalService,
    public fotoService: GaleriaFotoService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService,
    public auth: AuthService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.buscar();
  }

  buscar() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.fotoService.get(this.page, this.itemsPerPage);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  deletarData(foto: GaleriaFoto) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir item',
        description: 'Você realmente quer excluir esse item? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.present('Excluindo item...');
        this.fotoService.delete(foto).subscribe((res: any) => {
          this.helper.openSnackBar('Item removido com sucesso.');
          this.loadingService.dismiss();
          this.buscar();
        }, e => this.loadingService.dismiss());
      }
    })
  }

  onSelect(event) {

    this.files.push(...event.addedFiles);
    let count = event.addedFiles.length;

    this.loadingService.present('Inserindo');

    event.addedFiles.forEach(file => {
      const url = `/galeriaFoto/inserirMultiplo`;
      this.fotoService.postFile(file, url, 'arquivo')
        .subscribe((e: HttpProgressEvent | any) => {
          if (e.type === 4) {
            count--;
            if (count == 0) {
              this.progress = 0;
              this.loadingService.dismiss();
              this.files = [];
              this.buscar();
            }
          } else {
            this.progress = Math.round((e.loaded / e.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        })
    });
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  deletarImagem(imagem: GaleriaFoto, index: number) {
    this.loadingService.present('Deletando imagem...');
    this.fotoService.delete(imagem).subscribe(res => {
      this.data.splice(index, 1);
      this.buscar()
      this.loadingService.dismiss()
    }, e => this.loadingService.dismiss())
  }

}
