export class FichaTecnicaCampo {

    id: number
    idFichaTecnica: number
    nome: string = '';
    tipoCampo: string = '';
    ordem: number
    situacao: string = 'A';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}