import { FichaTecnica } from 'src/app/models/ficha-tecnica.model';
import { FichaTecnicaService } from 'src/app/services/class/ficha-tecnica.service';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { MatSlideToggleChange, MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { FichaTecnicaCampoService } from 'src/app/services/class/ficha-tecnica-campo.service';
import { FichaTecnicaCampo } from 'src/app/models/ficha-tecnica-campo.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-fichas-tecnicas-editar',
  templateUrl: './fichas-tecnicas-editar.component.html',
  styleUrls: ['./fichas-tecnicas-editar.component.scss']
})
export class FichasTecnicasEditarComponent implements OnInit {

  data: FichaTecnica = new FichaTecnica();
  nestedData: FichaTecnicaCampo[] = [];
  loading: boolean = false;
  search: string = '';

  buscarSubscription: Subscription;

  constructor(
    public helper: HelperService,
    public fichaTecnicaService: FichaTecnicaService,
    public fichaTecnicaCampoService: FichaTecnicaCampoService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.buscarData(param.id);
      this.buscarCamposFichaTecnica(param.id);
    });
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscarData(id: number) {
    this.loading = true;
    this.buscarSubscription = this.fichaTecnicaService.getById(id)
      .subscribe((res: FichaTecnica) => {
        this.data = res;
        this.data.campos = res.campos.map((c, i) => {
          c.novoCampo = false;
          c.ordem = i + 1;
          return c;
        })
        this.loading = false;
      }, e => this.loading = false);
  }

  buscarCamposFichaTecnica(idFicha: number) {
    this.fichaTecnicaCampoService.get(idFicha)
      .subscribe((res: FichaTecnicaCampo[]) => this.nestedData = res)
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando item...');

    this.fichaTecnicaService.patch(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/fichas-tecnicas']).then(() => {
          this.helper.openSnackBar('Item alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

  deletarCampo(campo: FichaTecnicaCampo, index: number) {

    if (!campo.id) {
      this.data.campos.splice(index, 1);
      return;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir item',
        description: 'Você realmente quer excluir esse item? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.present('Excluindo item...');
        this.fichaTecnicaCampoService.delete(campo).subscribe((res: any) => {
          this.helper.openSnackBar('Item removido com sucesso.');
          this.buscarData(this.data.id);
          this.buscarCamposFichaTecnica(this.data.id);
          this.loadingService.dismiss();
        }, e => this.loadingService.dismiss());
      }
    })
  }

  adicionarCampo() {
    const campo = new FichaTecnicaCampo();
    this.search = '';
    campo.id = 0;
    campo.ordem = this.data.campos.length + 1;
    campo.novoCampo = true;
    campo.idFichaTecnica = this.data.id;
    this.data.campos.push(campo);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data.campos, event.previousIndex, event.currentIndex);
    this.ordernar();
  }

  ordernar() {
    this.data.campos = this.data.campos.map((campo, index) => {
      campo.ordem = index + 1;
      return campo;
    });
    this.loadingService.present('Ordenando...');
    this.fichaTecnicaCampoService.order(this.data.campos)
      .subscribe(res => {
        this.helper.openSnackBar('Items ordenados.');
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());
  }

}
