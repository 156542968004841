export class LinhaProduto {

    id: number;
    nome: string = '';
    descricao: string = '';
    icone: string = '';
    imagem: string = '';
    imagemHome: string = '';
    imagemPequenaHome: string = '';
    videoHome: string = '';
    cor: string = '';
    situacao: string = 'A';
    slug: string = '';
    ordem: number = 99;
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}