import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Parceiro } from 'src/app/models/parceiro.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParceiroService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<ParceirosApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/parceiro/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/parceiro/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/parceiro/buscar?id=${id}`);
  }

  post(parceiro: Parceiro): Observable<any> {
    return this.api.post('/parceiro/inserir', parceiro);
  }

  patch(parceiro: Parceiro): Observable<any> {
    return this.api.post('/parceiro/alterar', parceiro);
  }

  delete(parceiro: Parceiro): Observable<any> {
    return this.api.post('/parceiro/deletar', parceiro);
  }

  deleteSelected(parceiros: Parceiro[]): Observable<any> {
    return this.api.post('/parceiro/deletarLista', parceiros);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface ParceirosApi {
  parceiros: Parceiro[];
  numeroPaginas: number;
}
