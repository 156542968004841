import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { ParceirosComponent } from './pages/parceiros/parceiros.component';
import { ParceirosCriarComponent } from './pages/parceiros-criar/parceiros-criar.component';
import { ParceirosEditarComponent } from './pages/parceiros-editar/parceiros-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { FotosComponent } from './pages/fotos/fotos.component';
import { VideosComponent } from './pages/videos/videos.component';
import { VideosCriarComponent } from './pages/videos-criar/videos-criar.component';
import { VideosEditarComponent } from './pages/videos-editar/videos-editar.component';
import { LinhasComponent } from './pages/linhas/linhas.component';
import { LinhasCriarComponent } from './pages/linhas-criar/linhas-criar.component';
import { LinhasEditarComponent } from './pages/linhas-editar/linhas-editar.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { ProdutosCriarComponent } from './pages/produtos-criar/produtos-criar.component';
import { ProdutosEditarComponent } from './pages/produtos-editar/produtos-editar.component';
import { ProjetosComponent } from './pages/projetos/projetos.component';
import { ProjetosCriarComponent } from './pages/projetos-criar/projetos-criar.component';
import { ProjetosEditarComponent } from './pages/projetos-editar/projetos-editar.component';
import { TiposProdutoComponent } from './pages/tipos-produto/tipos-produto.component';
import { TiposProdutoCriarComponent } from './pages/tipos-produto-criar/tipos-produto-criar.component';
import { TiposProdutoEditarComponent } from './pages/tipos-produto-editar/tipos-produto-editar.component';
import { ModelosProdutoComponent } from './pages/modelos-produto/modelos-produto.component';
import { ModelosProdutoCriarComponent } from './pages/modelos-produto-criar/modelos-produto-criar.component';
import { ModelosProdutoEditarComponent } from './pages/modelos-produto-editar/modelos-produto-editar.component';
import { FichasTecnicasComponent } from './pages/fichas-tecnicas/fichas-tecnicas.component';
import { FichasTecnicasCriarComponent } from './pages/fichas-tecnicas-criar/fichas-tecnicas-criar.component';
import { FichasTecnicasEditarComponent } from './pages/fichas-tecnicas-editar/fichas-tecnicas-editar.component';


const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent, canActivate: [IsLoggedInGuard] },
  { path: 'contato', component: ContatoComponent, canActivate: [IsLoggedInGuard] },
  { path: 'sobre', component: SobreComponent, canActivate: [IsLoggedInGuard] },
  { path: 'fotos', component: FotosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'videos', component: VideosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'videos/adicionar', component: VideosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'videos/editar/:id', component: VideosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'parceiros', component: ParceirosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'parceiros/adicionar', component: ParceirosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'parceiros/editar/:id', component: ParceirosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'linhas', component: LinhasComponent, canActivate: [IsLoggedInGuard] },
  { path: 'linhas/adicionar', component: LinhasCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'linhas/editar/:id', component: LinhasEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'produtos', component: ProdutosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'produtos/adicionar', component: ProdutosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'produtos/editar/:id', component: ProdutosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'projetos', component: ProjetosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'projetos/adicionar', component: ProjetosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'projetos/editar/:id', component: ProjetosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'tipos-produto', component: TiposProdutoComponent, canActivate: [IsLoggedInGuard] },
  { path: 'tipos-produto/adicionar', component: TiposProdutoCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'tipos-produto/editar/:id', component: TiposProdutoEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'modelos-produto', component: ModelosProdutoComponent, canActivate: [IsLoggedInGuard] },
  { path: 'modelos-produto/adicionar', component: ModelosProdutoCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'modelos-produto/editar/:id', component: ModelosProdutoEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios', component: UsuariosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios/adicionar', component: UsuariosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios/editar/:id', component: UsuariosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'fichas-tecnicas', component: FichasTecnicasComponent, canActivate: [IsLoggedInGuard] },
  { path: 'fichas-tecnicas/adicionar', component: FichasTecnicasCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'fichas-tecnicas/editar/:id', component: FichasTecnicasEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
