export class Projeto {

    id: number;
    nome: string = '';
    imagemDepoimento: string = '';
    depoimento: string = '';
    cargoDepoimento: string = '';
    nomeDepoimento: string = '';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}