import { LinhaProduto } from "./linha-produto.model";

export class TipoProduto {

    id: number;
    nome: string = '';
    descricao: string = '';
    imagem: string = '';
    linhaProduto: LinhaProduto;
    situacao: string = 'A';
    ordem: number = 99;
    slug: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}