import { FichaTecnica } from "./ficha-tecnica.model";
import { TipoProduto } from "./tipo-produto.model";

export class ModeloProduto {

    id: number;
    nome: string = '';
    tipoProduto: TipoProduto;
    fichaTecnica: FichaTecnica;
    ordem: number = 99;
    imagem: string = '';
    slug: string = '';
    situacao: string = 'A';

    constructor(obj?) {
        Object.assign(this);
    }
    
}