import { Produto } from 'src/app/models/produto.model';
import { ProdutoService } from 'src/app/services/class/produto.service';
import { ModeloProdutoService } from 'src/app/services/class/modelo-produto.service';
import { ModeloProduto } from 'src/app/models/modelo-produto.model';
import { FichaTecnicaCampo } from 'src/app/models/ficha-tecnica-campo.model';
// default
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { MatSlideToggleChange } from '@angular/material';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';
import { HttpProgressEvent } from '@angular/common/http';

@Component({
  selector: 'app-produtos-editar',
  templateUrl: './produtos-editar.component.html',
  styleUrls: ['./produtos-editar.component.scss']
})
export class ProdutosEditarComponent implements OnInit {

  data: Produto = new Produto();

  modelos: ModeloProduto[] = [];

  file: File;
  fileImagem: File;
  progress: any;

  req: Subscription;

  constructor(
    public helper: HelperService,
    public produtoService: ProdutoService,
    public modeloProdutoService: ModeloProdutoService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public global: GlobalService
  ) { }

  ngOnInit() {
    this.modeloProdutoService.getSelect().subscribe((res: ModeloProduto[]) => this.modelos = res);
    this.route.params.subscribe(param => this.buscar(param.id));
  }

  buscar(id: number) {
    this.req = this.produtoService.getById(id)
      .subscribe((res: Produto) => this.data = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando item...');

    this.data.slug = this.helper.convertToSlug(this.data.nome);
    this.produtoService.patch(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/produtos']).then(() => {
          this.helper.openSnackBar('Item alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });
  }

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  onFileSelected(files, campo: FichaTecnicaCampo) {
    this.file = files.item(0);
    this.loadingService.present('Enviando arquivo...');
    this.submitFiles(this.file, `/produtoFichaCampo/arquivo?id=${campo.id}`)
      .then((res: any) => {
        campo.valor = res.body;
        this.loadingService.dismiss();
      }).catch(() => {
        this.progress = 0;
        this.fileInput.nativeElement.value = '';
        this.loadingService.dismiss();
      });
  }

  submitFiles(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.produtoService.postFile(file, url, 'file')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }


  @ViewChild('fileImagemInput', { static: true }) fileImagemInput: ElementRef;
  onFileImagemSelected(files) {
    this.fileImagem = files.item(0);
    this.loadingService.present('Alterando...');
    this.submitImages(this.fileImagem, '/produtoFichaCampo/imagem?id=-99').then((res: any) => {
      this.data.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.produtoService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

}
