import { Produto } from 'src/app/models/produto.model';
import { ProdutoService } from 'src/app/services/class/produto.service';
import { LinhaProduto } from 'src/app/models/linha-produto.model';
import { LinhaProdutoService } from 'src/app/services/class/linha-produto.service';
// default
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { ModeloProdutoService } from 'src/app/services/class/modelo-produto.service';
import { ModeloProduto } from 'src/app/models/modelo-produto.model';

@Component({
  selector: 'app-produtos-criar',
  templateUrl: './produtos-criar.component.html',
  styleUrls: ['./produtos-criar.component.scss']
})
export class ProdutosCriarComponent implements OnInit {

  data: Produto = new Produto();

  modelos: ModeloProduto[] = [];

  fileImagem: File;
  fileImagemAplicada: File;
  progress: any;

  constructor(
    public helper: HelperService,
    public produtoService: ProdutoService,
    public modeloProdutoService: ModeloProdutoService,
    public loadingService: LoadingService,
    public router: Router,
    public auth: AuthService,
    public global: GlobalService
  ) { }

  ngOnInit() {
    this.modeloProdutoService.getSelect()
      .subscribe((res: ModeloProduto[]) => this.modelos = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo item...');

    this.data.slug = this.helper.convertToSlug(this.data.nome);
    this.produtoService.post(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/produtos/editar', res]).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

}
