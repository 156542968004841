import { GlobalService } from './../../services/global.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  search: string = '';
  pages = [
    { name: 'Contato', icon: 'fas fa-phone-alt', route: '/contato' },
    { name: 'Fotos', icon: 'fas fa-camera', route: '/fotos' },
    { name: 'Linhas', icon: 'fas fa-grip-lines', route: '/linhas' },
    { name: 'Parceiros', icon: 'fas fa-user-tie', route: '/parceiros' },
    { name: 'Produtos', icon: 'fas fa-boxes', route: '/produtos' },
    { name: 'Tipos de Produto', icon: 'fas fa-shapes', route: '/tipos-produto' },
    { name: 'Modelos de Produto', icon: 'fas fa-square', route: '/modelos-produto' },
    { name: 'Fichas Técnicas', icon: 'fas fa-table', route: '/fichas-tecnicas' },
    { name: 'Projetos', icon: 'fas fa-lightbulb', route: '/projetos' },
    { name: 'Sobre', icon: 'fas fa-info', route: '/sobre' },
    { name: 'Usuários', icon: 'fas fa-users', route: '/usuarios' },
    { name: 'Videos', icon: 'fas fa-video', route: '/videos' },
  ]

  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  goTo(page: string) {
    this.router.navigate([page]).then(() => {
      this.global.menuOpen = false;
    });
  }

  logout() {
    this.router.navigate(['/login']).then(() => {
      this.global.menuOpen = false;
      this.auth.logout();
    })
  }

}
