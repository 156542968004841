import { GaleriaVideo } from 'src/app/models/galeria-video.model';
import { GaleriaVideoService } from 'src/app/services/class/galeria-video.service';
// default
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-videos-editar',
  templateUrl: './videos-editar.component.html',
  styleUrls: ['./videos-editar.component.scss']
})
export class VideosEditarComponent implements OnInit {

  data: GaleriaVideo = new GaleriaVideo();

  constructor(
    public helper: HelperService,
    public _videoService: GaleriaVideoService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public global: GlobalService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscar(param.id))
  }

  buscar(id: number) {
    this._videoService.getById(id)
      .subscribe((res: GaleriaVideo) => this.data = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando item...');

    this._videoService.patch(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/videos']).then(() => {
          this.helper.openSnackBar('Item alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });
  }
}
