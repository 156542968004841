import { GaleriaVideo } from 'src/app/models/galeria-video.model';
import { GaleriaVideoService } from 'src/app/services/class/galeria-video.service';
// default
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-videos-criar',
  templateUrl: './videos-criar.component.html',
  styleUrls: ['./videos-criar.component.scss']
})
export class VideosCriarComponent implements OnInit {

  data: GaleriaVideo = new GaleriaVideo();

  constructor(
    public helper: HelperService,
    public _videoService: GaleriaVideoService,
    public loadingService: LoadingService,
    public router: Router,
    public auth: AuthService,
    public global: GlobalService
  ) { }

  ngOnInit(): void { }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo item...');

    this._videoService.post(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/videos']).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });
  }
}
