import { FichaTecnica } from 'src/app/models/ficha-tecnica.model';
import { FichaTecnicaService } from 'src/app/services/class/ficha-tecnica.service';
// default
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fichas-tecnicas-criar',
  templateUrl: './fichas-tecnicas-criar.component.html',
  styleUrls: ['./fichas-tecnicas-criar.component.scss']
})
export class FichasTecnicasCriarComponent implements OnInit {

  data: FichaTecnica = new FichaTecnica();

  constructor(
    public helper: HelperService,
    public fichaTecnicaService: FichaTecnicaService,
    public loadingService: LoadingService,
    public router: Router,
  ) { }

  ngOnInit() {
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo item...');

    this.fichaTecnicaService.post(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/fichas-tecnicas/editar', res]).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

}
