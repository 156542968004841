import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GaleriaVideo } from 'src/app/models/galeria-video.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class GaleriaVideoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<VideosApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/galeriaVideo/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/galeriaVideo/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/galeriaVideo/buscar?id=${id}`);
  }

  post(video: GaleriaVideo): Observable<any> {
    return this.api.post('/galeriaVideo/inserir', video);
  }

  patch(video: GaleriaVideo): Observable<any> {
    return this.api.post('/galeriaVideo/alterar', video);
  }

  delete(video: GaleriaVideo): Observable<any> {
    return this.api.post('/galeriaVideo/deletar', video);
  }

  deleteSelected(videos: GaleriaVideo[]): Observable<any> {
    return this.api.post('/galeriaVideo/deletarLista', videos);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface VideosApi {
  videos: GaleriaVideo[];
  numeroPaginas: number;
}
