import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { FichaTecnica } from 'src/app/models/ficha-tecnica.model';

@Injectable({
  providedIn: 'root'
})
export class FichaTecnicaService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<FichasTecnicasApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/fichaTecnica/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/fichaTecnica/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/fichaTecnica/buscar?id=${id}`);
  }

  getSelect(): Observable<any> {
    return this.api.get(`/select/fichaTecnica/buscar`);
  }

  post(fichaTecnica: FichaTecnica): Observable<any> {
    return this.api.post('/fichaTecnica/inserir', fichaTecnica);
  }

  patch(fichaTecnica: FichaTecnica): Observable<any> {
    return this.api.post('/fichaTecnica/alterar', fichaTecnica);
  }

  delete(fichaTecnica: FichaTecnica): Observable<any> {
    return this.api.post('/fichaTecnica/deletar', fichaTecnica);
  }

  deleteSelected(fichasTecnicas: FichaTecnica[]): Observable<any> {
    return this.api.post('/fichaTecnica/deletarLista', fichasTecnicas);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: FichaTecnica, v2: FichaTecnica): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface FichasTecnicasApi {
  fichas: FichaTecnica[];
  numeroPaginas: number;
}
