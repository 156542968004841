import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GaleriaFoto } from 'src/app/models/galeria-foto.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class GaleriaFotoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number): Observable<GaleriaFoto[]> {
    return this.api.get(`/galeriaFoto/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}`);
  }

  post(foto: GaleriaFoto): Observable<any> {
    return this.api.post('/galeriaFoto/inserir', foto);
  }

  delete(foto: GaleriaFoto): Observable<any> {
    return this.api.post('/galeriaFoto/deletar', foto);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface FotosApi {
  fotos: GaleriaFoto[];
  numeroPaginas: number;
}
