import { LinhaProduto } from './linha-produto.model';
import { ModeloProduto } from './modelo-produto.model';
import { ProdutoFichaCampo } from './produto-ficha-campo.model';

export class Produto {

    id: number;
    nome: string = '';
    modelo: ModeloProduto;
    situacao: string = 'A';
    campos: ProdutoFichaCampo[] = [];

    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}